import { request as axios, request } from '@/util/request';
const clean = require('bmh/clean-empty-obj');
const getUrl = require('bmh/url-with-param');
async function getStock(send_data: any ={} , current: number = 1) {
    clean(send_data);
    const _send_data = {
      ...send_data,
      current,
    };
    const url = getUrl('/boss/factory/warehouse/pd/stock', _send_data);
    const res = await axios.get(url)
    return res;
}
export {
    getStock
}
